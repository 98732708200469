import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook from react-router-dom
import { Box, Button, IconButton, Typography, Paper } from "@mui/material";
import AddressInput from "./AddressInput";
import AddStopButton from "./AddStopButton";
import QuoteResult from "./QuoteResult";
import { calculateDistanceAndTime } from "../services/calculateDistanceAndTime";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const QuoteForm = () => {
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropoffAddress, setDropoffAddress] = useState(null);
  const [stops, setStops] = useState([]);
  const [quote, setQuote] = useState(null);
  const navigate = useNavigate(); // Hook to navigate programmatically

  const handleAddStop = () => {
    setStops([...stops, null]);
  };

  const handleRemoveStop = (index) => {
    const newStops = stops.filter((_, i) => i !== index);
    setStops(newStops);
  };

  const handleMoveStop = (index, direction) => {
    const newStops = [...stops];
    if (direction === "up" && index > 0) {
      [newStops[index - 1], newStops[index]] = [
        newStops[index],
        newStops[index - 1],
      ];
    } else if (direction === "down" && index < stops.length - 1) {
      [newStops[index], newStops[index + 1]] = [
        newStops[index + 1],
        newStops[index],
      ];
    }
    setStops(newStops);
  };

  const handleStopChange = (index, value) => {
    const newStops = [...stops];
    newStops[index] = value;
    setStops(newStops);
  };

  const calculateDistanceAndCost = async () => {
    if (!pickupAddress || !dropoffAddress) {
      alert("Veuillez entrer l'adresse de départ et de destination.");
      return;
    }

    try {
      const stopAddresses = stops
        .filter((stop) => stop && stop.value && stop.value.description)
        .map((stop) => stop.value.description);

      const { distance, duration } = await calculateDistanceAndTime(
        pickupAddress.value.description,
        dropoffAddress.value.description,
        "08:00",
        stopAddresses
      );

      let distanceInKm = parseFloat(distance.replace(",", "."));
      let durationInMinutes = parseFloat(duration.split(" ")[0]);
      const costPerKm = 1.5;
      const costPerMinute = 0.3;
      let totalCost =
        distanceInKm * costPerKm + durationInMinutes * costPerMinute;
      totalCost = totalCost < 24.3 ? 24.3 : totalCost;

      setQuote({
        distance: distanceInKm,
        duration: durationInMinutes,
        cost: totalCost.toFixed(2),
      });
    } catch (error) {
      console.error(
        "Erreur lors du calcul de la distance et du temps :",
        error.message
      );
      alert(
        "Erreur lors du calcul de la distance. Veuillez vérifier les adresses et réessayer."
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    calculateDistanceAndCost();
  };

  const handleOrderTrip = () => {
    navigate("/order-confirm", {
      state: { pickupAddress, dropoffAddress, stops, quote },
    }); // Navigate to the order confirmation page and pass state
  };

  return (
    <Paper
      elevation={3}
      sx={{ padding: 4, maxWidth: 500, margin: "auto", marginTop: 4 }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Commander un Trajet
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <AddressInput
          label="Adresse de Départ"
          value={pickupAddress}
          onChange={setPickupAddress}
        />
        <AddStopButton onClick={handleAddStop} />
        {stops.map((stop, index) => (
          <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
            <AddressInput
              label={`Arrêt ${index + 1}`}
              value={stop}
              onChange={(value) => handleStopChange(index, value)}
            />
            <IconButton onClick={() => handleMoveStop(index, "up")}>
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton onClick={() => handleMoveStop(index, "down")}>
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton onClick={() => handleRemoveStop(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <AddressInput
          label="Adresse de Destination"
          value={dropoffAddress}
          onChange={setDropoffAddress}
          isDestination
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ marginTop: 2 }}
        >
          Calculer le Devis
        </Button>
        {quote && (
          <>
            <QuoteResult
              distance={quote.distance}
              duration={quote.duration}
              cost={quote.cost}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOrderTrip}
              sx={{ marginTop: 2 }}
            >
              Commander le trajet
            </Button>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default QuoteForm;
