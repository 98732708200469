import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

function OrderConfirmation() {
  const location = useLocation();
  const { pickupAddress, dropoffAddress, stops, quote } = location.state;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [useWhatsApp, setUseWhatsApp] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Submit these details to your backend or wherever necessary
    console.log({
      pickupAddress: pickupAddress.value.description,
      dropoffAddress: dropoffAddress.value.description,
      stops: stops.length,
      cost: quote.cost,
      phoneNumber,
      date,
      time,
      useWhatsApp,
    });
    alert("Order confirmed!");
  };

  return (
    <Box sx={{ maxWidth: 500, mx: "auto", mt: 4, p: 4, boxShadow: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Confirmation de Commande
      </Typography>
      <Typography variant="body1">
        Adresse de départ: {pickupAddress.value.description}
      </Typography>
      <Typography variant="body1">
        Adresse de destination: {dropoffAddress.value.description}
      </Typography>
      <Typography variant="body1">Nombre d'arrêts: {stops.length}</Typography>
      <Typography variant="body1">Coût estimé: {quote.cost} €</Typography>
      <form onSubmit={handleFormSubmit}>
        <TextField
          fullWidth
          label="Numéro de téléphone"
          variant="outlined"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Date du trajet"
          type="date"
          variant="outlined"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
        />
        <TextField
          fullWidth
          label="Heure du trajet"
          type="time"
          variant="outlined"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={useWhatsApp}
              onChange={(e) => setUseWhatsApp(e.target.checked)}
            />
          }
          label="Préférer la communication via WhatsApp"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Confirmer la commande
        </Button>
      </form>
    </Box>
  );
}

export default OrderConfirmation;
