// src/components/AddStopButton.js
import React from "react";
import { IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const AddStopButton = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      color="primary"
      sx={{ alignSelf: "center", marginBottom: 2 }}
    >
      <AddCircleOutlineIcon />
    </IconButton>
  );
};

export default AddStopButton;
