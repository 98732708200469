// src/components/QuoteResult.js
import React from "react";
import { Box, Typography } from "@mui/material";

const QuoteResult = ({ cost }) => {
  return (
    <Box mt={4} textAlign="center">
      <Typography variant="h6">Devis estimé : {cost} € HT</Typography>
    </Box>
  );
};

export default QuoteResult;
