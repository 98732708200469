// src/components/AddressInput.js
import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Box } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

const AddressInput = ({ label, value, onChange, isDestination }) => {
  // Définir les pays de l'Europe pour les restrictions
  const europeanCountries = [
    "fr",
    "be",
    "de",
    "it",
    "es",
    "pt",
    "nl",
    "lu",
    "ch",
    "at",
    "dk",
    "fi",
    "gr",
    "ie",
    "no",
    "pl",
    "se",
    "cz",
    "hu",
    "bg",
    "ro",
    "sk",
    "si",
    "ee",
    "lv",
    "lt",
    "mt",
    "cy",
    "hr",
  ];

  return (
    <Box sx={{ marginBottom: 2, position: "relative" }}>
      {/* Composant Google Places Autocomplete avec restrictions géographiques */}
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} // Utiliser la clé API de l'environnement
        selectProps={{
          value: value,
          onChange: (selected) => {
            onChange(selected); // Appeler directement la fonction onChange avec la sélection
          },
          placeholder: label,
          styles: {
            control: (provided) => ({
              ...provided,
              width: "100%",
              height: "56px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              paddingLeft: "40px", // Laisser de l'espace pour l'icône
              boxShadow: "none",
              transition: "border-color 0.3s ease",
              "&:hover": {
                borderColor: "#1976d2",
              },
              "&:focus": {
                borderColor: "#1976d2",
              },
            }),
            input: (provided) => ({
              ...provided,
              fontSize: "16px",
              color: "#333",
            }),
            singleValue: (provided) => ({
              ...provided,
              paddingLeft: "8px",
            }),
          },
          components: {
            DropdownIndicator: () => null, // Retirer l'indicateur par défaut de dropdown
          },
        }}
        // Restrictions pour la recherche : Montpellier et pays d'Europe
        autocompletionRequest={{
          location: {
            lat: 43.6119, // Latitude de Montpellier
            lng: 3.8772, // Longitude de Montpellier
          },
          radius: 50000, // Rayon de 50 km autour de Montpellier
          componentRestrictions: {
            country: europeanCountries, // Liste des pays européens
          },
        }}
      />

      {/* Icône personnalisée placée à l'intérieur du champ */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "12px",
          transform: "translateY(-50%)",
          pointerEvents: "none", // Ignorer les clics sur l'icône
          color: "#1976d2", // Couleur de l'icône pour correspondre au thème
        }}
        aria-label={isDestination ? "Destination" : "Départ"} // Ajouter des ARIA labels
      >
        {isDestination ? (
          <DirectionsCarIcon color="inherit" />
        ) : (
          <LocationOnIcon color="inherit" />
        )}
      </Box>
    </Box>
  );
};

export default AddressInput;
