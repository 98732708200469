import axios from "axios";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

/**
 * Fonction pour calculer la distance et la durée entre deux adresses.
 * @param {string} origin Adresse de départ.
 * @param {string} destination Adresse d'arrivée.
 * @param {string} time Heure de départ au format 'HH:MM'.
 * @returns {Promise<Object>} Un objet contenant la distance et la durée.
 */
export const calculateDistanceAndTime = async (
  origin,
  finalDestination,
  time,
  stops = []
) => {
  console.log("Adresse de départ:", origin);
  console.log("Arrêts:", stops);
  console.log("Adresse de destination finale:", finalDestination);
  console.log("Heure de départ:", time);

  try {
    const departureTime = new Date(`2024-09-01T${time}:00`).getTime() / 1000;
    const allDestinations = [...stops, finalDestination].join("|");

    const response = await axios.get(
      "https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/distancematrix/json",
      {
        params: {
          origins: origin,
          destinations: allDestinations,
          departure_time: departureTime,
          key: API_KEY,
          mode: "driving",
        },
      }
    );

    if (response.data) {
      console.log("Données de l'API reçues:", response.data);

      let totalDistance = 0;
      let totalDuration = 0;

      // Parcourir les résultats pour calculer la distance et la durée totales
      response.data.rows[0].elements.forEach((element, index) => {
        if (element.status === "OK") {
          const distance = parseFloat(element.distance.text.replace(",", "."));
          const duration = parseFloat(element.duration.text.split(" ")[0]);

          totalDistance += distance;
          totalDuration += duration;

          console.log(
            `Segment ${
              index + 1
            }: Distance ${distance} km, Durée ${duration} minutes`
          );
        } else {
          console.error(
            `Erreur pour le segment ${index + 1}: ${element.status}`
          );
        }
      });

      return {
        distance: totalDistance.toFixed(2) + " km",
        duration: totalDuration.toFixed(2) + " minutes",
      };
    } else {
      console.error("Erreur: La réponse de l'API est vide ou mal formée.");
      throw new Error("La réponse de l'API est vide ou mal formée.");
    }
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des données de distance:",
      error.message
    );
    throw error;
  }
};
