import React from "react";
import { Routes, Route } from "react-router-dom";
import QuoteForm from "./components/QuoteForm";
import OrderConfirmation from "./components/OrderConfirmation"; // Assume this is your order confirmation component

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<QuoteForm />} />
        <Route path="/order-confirm" element={<OrderConfirmation />} />
      </Routes>
    </div>
  );
}

export default App;
